<template>
  <div class="headers">
    <span
      v-for="(item, index) in $store.state.customData.jumpbtn"
      :key="index"
      @click="gotoPage(item.nav_url)"
      >{{ item.title }}</span
    >
  </div>
</template>

<script>
export default {
  methods: {
    gotoPage(val) {
      window.open(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.headers {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 56px;
  background-color: $backgroundBase;
  span {
    padding: 0 16px;
    font-weight: 800;
    color: $textPrimary;
    user-select: none;
    cursor: pointer;
  }
}
</style>
