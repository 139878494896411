<template>
  <div id="shop_app">
    <div class="out-box">
      <div>
        <headers v-if="show && looktype == 1" />
        <router-view v-if="show" />
      </div>
      <footers v-if="$route.name !='categoryList'"/>
    </div>
    <themColor ref="themColor" />
    <asideIcon></asideIcon>
  </div>
</template>
<script>
const defaultColor = {
  primary: "#409EFF", // 默认颜色
  success: "#67C23A", // 成功色
  warning: "#E6A23C", // 警告色
  danger: "#F56C6C", // 危险色
  info: "#909399", // 信息色
  textPrimary: "#303133", // 默认字体色
  textRegular: "#606266", // 规律字体色
  textSecondary: "#909399", // 次要字体色
  textPlaceholder: "#C0C4CC", // 输入框提示字体色
  borderBase: "#DCDFE6", // 一级边框色
  borderLight: "#E4E7ED", // 二级边框色
  borderLighter: "#EBEEF5", // 三级边框色
  borderExtraLight: "#F2F6FC", // 四级边框色
  white: "#FFFFFF", // 一级背景色
  black: "#000000", // 二级背景黑色
  backgroundBase: "#F5F7FA", // 头部背景色
};
import footers from "@/views/components/footer.vue";
import themColor from "@/components/themColor.vue";
import headers from "@/views/components/headers.vue";
import asideIcon from '@/components/asideIcon'
import {
  custHomeGetData,
  name_logo,
  company_detail,
} from "@/assets/api/index.js";
export default {
  components: {
    headers,
    themColor,
    footers,
    asideIcon
  },
  data() {
    return {
      show: false,
    };
  },
  created() {},
  mounted() {
    this.init();
    console.log(this.$route,'000000000000000')
  },
  methods: {
    init() {
      custHomeGetData({ company_id: this.$store.state.cid }).then((res) => {
        this.$store.commit("changeCustomData", res.data);
        if (res.data?.theme?.attr || false) {
          let array = [];
          for (let keys in defaultColor) {
            if (res.data.theme.attr[keys] != defaultColor[keys]) {
              array.push([res.data.theme.attr[keys], defaultColor[keys]]);
            }
          }
          //   this.$refs.themColor.theme(array);
        }
        this.show = true;
      });
      name_logo().then((res) => {
        console.log(res);
        let param = {
          name: res.data.value.platform.name,
          logo: res.data.value.logo.img_url,
          favicon: res.data.value.favicon,
        };
        this.$store.commit("updateName_logo", param);

        if (this.$store.state.cid) {
          company_detail({ company_id: this.$store.state.cid }).then((res) => {
            console.log(res);
            document.title = param.name + "-" + res.data.name;
          });
        } else {
          document.title =
            param.name + "-" + res.data.value.platform.description; // 用的描述
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
#shop_app {
  font-size: 14px;
  min-height: 100vh;
  background-color: #fafafa;
}

#nprogress .bar {
  background: $primary !important; //这里可以随便写颜色
}

.ellipsisText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.duohangEllipsisText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 76px; //  3行的时候哈
}

.duohangEllipsisTextTwo {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 52px; //  2行的时候哈
}
.out-box{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>